import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Speedometer, {
    Background,
    Arc,
    Needle,
    Progress,
    Marks,
    Indicator,
    DangerPath
  } from 'react-speedometer';
import { Map, Marker } from '@googlemaps/react-wrapper'
import AirplayOutlinedIcon from '@mui/icons-material/AirplayOutlined';
import KeyIcon from '@mui/icons-material/Key';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import { IgrRadialGauge } from 'igniteui-react-gauges';
import LiquidFillGauge from 'react-liquid-gauge';
import Thermometer from 'react-thermometer-component'
import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import Box from '@mui/material/Box';
import Sidebar from './Sidebar';
import axios from 'axios';
import './App.css';

const eventTypes = [
    "position",
    "infraccion",
    "login chofer",
    "ignicion",
    "entrada",
    "jamming",
    "power",
    "GPS",
    "GSM",
    "mensaje evento",
    "frenada brusca",
    "giro brusco",
    "logout chofer",
    "encendido",
    "login empleado",
    "logout empleado",
    "contenedor servido",
    "sonda",
    "ralenti",
    "cable USB",
    "SD card",
    "antena GPS"
];

const url = "http://193.31.31.98:3001";


function Consola() {
    const { imei } = useParams();

    const [positions, setPositions] = useState([]);
    const [last_position, setLastPosition] = useState({});

    let startColor = '#6495ed'; // cornflowerblue
    let endColor = '#dc143c'; // crimson

    useEffect(() => {
        const fetchData = () => {
            axios.get(`${url}/events/${imei}`)
            .then(response => {
                console.log(response.data)
                setPositions(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            
            axios.get(`${url}/positions/last/${imei}`)
            .then(response => {
                console.log("last_position");
                console.log(response.data)
                setLastPosition(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        }

        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 2 minutes
          }, 2000);
        
        return () => clearInterval(intervalId);
    }, []);

    let getEventMessage = (position) => {
        switch(position.usertag_0[0])
        {
            case 2:
                // login chofer
                return position.usertag_2.toString()+
                        position.usertag_3.toString()+
                        position.usertag_4.toString()+
                        position.usertag_5.toString()+
                        position.usertag_6.toString()+
                        position.usertag_7.toString();
            case 3:
                // ignicion
                return position.usertag_1[0] == 1 ? "ON" : "OFF";

            case 4:
                // entrada
                let estado = position.usertag_2[0] > 0 ? "ON" : "OFF"
                return position.usertag_1[0]+" "+estado;
            case 5:
                // jamming
                return position.usertag_1[0] == 1 ? "ON" : "OFF";
            case 6:
                // alimentacion principal
                return position.usertag_1[0] == 1 ? "ON" : "OFF";
            case 7:
                // GPS signal
                return position.usertag_1[0] == 1 ? "Ganancia" : "Perdida";
            case 8:
                // GSM signal
                return position.usertag_1[0] == 1 ? "Ganancia" : "Perdida";
            case 12:
                // logout chofer
                return position.usertag_2.toString()+
                    position.usertag_3.toString()+
                    position.usertag_4.toString()+
                    position.usertag_5.toString()+
                    position.usertag_6.toString()+
                    position.usertag_7.toString();
            case 13:
                // encendido
                return position.usertag_1[0] == 1 ? "ON" : "OFF";
            case 14:
                // login empleado
                return position.usertag_2.toString()+
                    position.usertag_3.toString()+
                    position.usertag_4.toString()+
                    position.usertag_5.toString()+
                    position.usertag_6.toString()+
                    position.usertag_7.toString();
            case 15:
                // logout empleado
                return position.usertag_2.toString()+
                    position.usertag_3.toString()+
                    position.usertag_4.toString()+
                    position.usertag_5.toString()+
                    position.usertag_6.toString()+
                    position.usertag_7.toString();
            case 16:
                // contenedor servido
                return position.usertag_2.toString()+
                    position.usertag_3.toString()+
                    position.usertag_4.toString()+
                    position.usertag_5.toString()+
                    position.usertag_6.toString()+
                    position.usertag_7.toString();
            // case 17:
                // sonda no muestra asi porque deberia haber una seccion aparte
            case 18:
                // ralenti
                return position.usertag_1[0] == 1 ? "ON" : "OFF";
            case 19:
                // cable USB
                return position.usertag_1[0] == 1 ? "Conectado" : "Desconectado"
            case 20:
                // memoria SD
                return position.usertag_0[0] == 1 ? "Conectada" : "Desconectada"
            case 21:
                // antena GPS
                return position.usertag_0[0] == 1 ? "Conectada" : "Desconectada"
            default:
                return "evento desconocido";
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'event', headerName: 'Evento', width: 100 },
        { field: 'message', headerName: 'Mensaje', width: 100},
        { field: 'date', headerName: 'Fecha', width: 160 },
    ];

    var rows = [];
    positions.forEach((position, i) => {
        if (position.usertag_0)
        {
            if (position.usertag_0[0] != 0)
            {
                rows.push({ 
                    id: i, 
                    date: position.date_time, 
                    event: eventTypes.length < position.usertag_0[0] 
                        ? position.usertag_0[0] 
                        : eventTypes[position.usertag_0[0]],
                    message: getEventMessage(position)
                });
            }
        }
    });

    let can_properties = {
        rpm: last_position.CAN_A1 != undefined ? last_position.CAN_A1.rpm : 0,
        fuel: last_position.CAN_A1 != undefined ? last_position.CAN_A1.fuel_level : 0,
        //fuel_consumed: last_position.CAN_A0 != undefined ? last_position.CAN_A0.fuel_used : 0,
        fuel_consumed: last_position.CAN16BITR5,
        temperature: last_position.CAN_A1 != undefined ? last_position.CAN_A1.temperatura : 0,
        velocidad: 0
    };

    console.log(can_properties.rpm);

    const radius = 50;
    const interpolate = interpolateRgb(startColor, endColor);
    const fillColor = interpolate(can_properties.fuel / 100);
    const gradientStops = [
        {
            key: '0%',
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: '0%'
        },
        {
            key: '50%',
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: '50%'
        },
        {
            key: '100%',
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: '100%'
        }
    ];

    let googlemaps_link = `https://www.google.com/maps/search/?api=1&query=${last_position.coord != undefined ? last_position.coord.lat : 0},${last_position.coord != undefined ? last_position.coord.long : 0}`;

    return (
        <div className="App" id="outer-container">
            <div id="page-wrap">
                <Box>
                    <div class="row">
                        <div class="column" style={{ width: '30%'}}>
                            <h2>IoT Team™</h2>
                        </div>
                        <div class="column" style={{ width: '35%'}}>
                            <h1>Dashboard</h1>
                            <h2><AirplayOutlinedIcon fontSize='medium' /> {imei}</h2>
                        </div>
                        <div class="column right" style={{ width: '30%'}}>
                            <p>Ultima Conexion: {last_position.date_time}</p>
                        </div>
                    </div>
                </Box>
                <div class="row">
                    <div class="column" style={{ width: '50%' }}>
                        <div class="row">
                            <div class="column" style={{ height: 400, width: '60%'}}>
                                <h3>Eventos</h3>
                                <DataGrid
                                    slots={{ toolbar: GridToolbar }}
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    disableRowSelectionOnClick
                                />
                            </div>
                            <div class="column" style={{ height: 400, width: '40%'}}>
                                <h3>Status</h3>
                                <Divider />
                                <p>Velocidad: {last_position.vel_curso != undefined ? last_position.vel_curso.vel : 0}km/h</p>
                                <p>Curso: {last_position.vel_curso != undefined ? last_position.vel_curso.direc : 0}</p>
                                <p>Altura: {last_position.altura ?? 0}m</p>
                                <Divider/>
                                <p>Posicion Actual:</p>
                                <p>{last_position.coord != undefined ? last_position.coord.lat : ""}</p>
                                <p>{last_position.coord != undefined ? last_position.coord.long : ""}</p>
                                <a href={googlemaps_link} target="_blank">Ver en Google Maps</a>
                                <Divider />
                                <p><BatterySaverIcon fontSize='small'/>Bateria: {last_position.tension_bateria/1000}v</p>
                                <p><ElectricBoltIcon fontSize='small'/>Alimentacion: {last_position.tension_alimentacion/1000}v</p>
                                <p><KeyIcon fontSize='small'/>Ignicion: {last_position.in_0 == 1 ? "ON" : "OFF"}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <h3>CAN</h3>
                        <Divider/>
                            <div class="row">
                                <div class="column left" style={{ width: '40%' }}>
                                    <div class="row">
                                    <p>Combustible</p>
                                    <LiquidFillGauge
                                    style={{ margin: '0 auto' }}
                                    width={radius * 2}
                                    height={radius * 2}
                                    value={can_properties.fuel}
                                    percent="%"
                                    textSize={1}
                                    textOffsetX={0}
                                    textOffsetY={0}
                                    textRenderer={(props) => {
                                        const value = Math.round(props.value);
                                        const radius = Math.min(props.height / 2, props.width / 2);
                                        const textPixels = (props.textSize * radius / 2);
                                        const valueStyle = {
                                            fontSize: textPixels
                                        };
                                        const percentStyle = {
                                            fontSize: textPixels * 0.6
                                        };
                
                                        return (
                                            <tspan>
                                                <tspan className="value" style={valueStyle}>{value}</tspan>
                                                <tspan style={percentStyle}>{props.percent}</tspan>
                                            </tspan>
                                        );
                                    }}
                                    riseAnimation
                                    waveAnimation
                                    waveFrequency={2}
                                    waveAmplitude={1}
                                    gradient
                                    gradientStops={gradientStops}
                                    circleStyle={{
                                        fill: fillColor
                                    }}
                                    waveStyle={{
                                        fill: fillColor
                                    }}
                                    textStyle={{
                                        fill: color('#444').toString(),
                                        fontFamily: 'Arial'
                                    }}
                                    waveTextStyle={{
                                        fill: color('#fff').toString(),
                                        fontFamily: 'Arial'
                                    }}
                                    />
                                    </div>
                                    <p>Total consumido: {can_properties.fuel_consumed}L</p>
                                    <Divider/>
                                    <p>Temperatura</p>
                                    <Thermometer
                                    theme="light"
                                    value={can_properties.temperature}
                                    max="300"
                                    steps="4"
                                    format="°C"
                                    size="medium"
                                    height="170"
                                    />
                                </div>
                                <div class="column">
                                    <div class="row">
                                        <div class="column">
                                            <div class="row">
                                                <p>RPM</p>
                                                <Speedometer
                                                value={can_properties.rpm/1000}
                                                max={6}
                                                fontFamily='squada-one'
                                                width={170}
                                                >
                                                    <Background />
                                                    <Arc arcWidth={4} />
                                                    <Needle
                                                        baseOffset={40}
                                                        circleRadius={30}
                                                    />
                                                    <DangerPath/>
                                                    <Progress arcWidth={10} />
                                                    <Marks step={0.5} />
                                                </Speedometer>
                                                <p>{Math.floor(can_properties.rpm)}</p>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="row">
                                                <p>Tacometro</p>
                                                <Speedometer
                                                value={can_properties.velocidad}
                                                fontFamily='squada-one'
                                                width={170}
                                                >
                                                    <Background />
                                                    <Arc/>
                                                    <Needle/>
                                                    <DangerPath/>
                                                    <Progress/>
                                                    <Marks/>
                                                    <Indicator fontSize={20}/>
                                                </Speedometer>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column center">
                                            <p>Presion de Aceite</p>
                                            <Speedometer
                                                value={can_properties.velocidad}
                                                fontFamily='squada-one'
                                                width={170}
                                                max={100}
                                                >
                                                <Background fill='#555'/>
                                                <Arc/>
                                                <Needle/>
                                                <DangerPath/>
                                                <Progress/>
                                                <Marks/>
                                                <Indicator fontSize={20}/>
                                                </Speedometer>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Consola;