import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { createBrowserHistory } from 'history';
import Sidebar from './Sidebar';
import axios from 'axios';
import './App.css';

function Devices() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('http://193.31.31.98:3001/devices')
      .then(response => {
        console.log(response.data)
        setPosts(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  console.log(posts);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'imei', headerName: 'IMEI', width: 200 },
    { field: 'lastConnection', headerName: 'Ultima Conexion', width: 230 },
    { field: 'lastPosition', headerName: 'Ultima Posicion', width: 230 },
    { field: 'battery', headerName: 'Bateria', width: 120 },
    { field: 'mainPower', headerName: 'Alimentacion', width: 120 },
    { field: 'ignition', headerName: 'Ignicion', width: 100 }
  ];

  var rows = [];
  posts.forEach((post, i) => {
    let position = post.last_position.lat + ", " + post.last_position.long;
    rows.push({ 
      id: i, imei: post.imei, 
      lastConnection: post.last_connection, 
      lastPosition: position,
      battery: post.tension_bateria / 1000 + "v",
      mainPower: post.tension_alimentacion / 1000 + "v",
      ignition: post.estado_ignicion > 10000 ? "ON" : "OFF"
    });
  });

  const handleRowClick = (params) => {
    console.log(params.row.imei);
    window.location.href = `/consola/${params.row.imei}`;
  };

  return (
    <div className="App" id="outer-container">
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <div id="page-wrap">
        <h1>GalileoSky Parser</h1>
        <h2>Dashboard de monitoreo de dispositivos</h2>
        <h2>Setee la IP del servidor del dispositivo a IP y PUERTO</h2>
        <p>IoT quiero</p>
      </div>
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default Devices;