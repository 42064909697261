import React from 'react';
import { BrowserRouter, Switch, Route, Routes, Router } from "react-router-dom";
import Devices from './Devices';
import Consola from './Consola';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Devices />}>
        </Route>
      </Routes>
      <Routes>
        <Route path="/consola/:imei" element={<Consola />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;